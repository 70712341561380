<template>
  <div class="html">
    <div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item class="item" index="1">常用网站</el-menu-item>
        <el-menu-item class="item" index="2">小游戏相关</el-menu-item>
        <el-menu-item class="item" index="3">工具页面</el-menu-item>
        <el-menu-item class="item" index="4">摸鱼相关</el-menu-item>
        <el-menu-item class="item" index="5">待开发</el-menu-item>
      </el-menu>
    </div>
    <div>
      <div v-if="common">
        <common ref="common" />
      </div>
      <div v-if="minigame">
        <minigame ref="minigame" />
      </div>
      <div v-if="trial">
        <trial ref="trial" />
      </div>
      <div v-if="fish">
        <fish ref="fish" />
      </div>
      <div v-if="develop">555</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    common: () => import("../components/common.vue"),
    minigame: () => import("../components/minigame.vue"),
    trial: () => import("../components/trial.vue"),
    fish: () => import("../components/fish.vue"),
  },
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      common: true,
      minigame: false,
      trial: false,
      fish: false,
      develop: false,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == 1) {
        this.common = true;
        this.minigame = false;
        this.trial = false;
        this.fish = false;
        this.develop = false;
      } else if (key == 2) {
        this.common = false;
        this.minigame = true;
        this.trial = false;
        this.fish = false;
        this.develop = false;
      } else if (key == 3) {
        this.common = false;
        this.minigame = false;
        this.trial = true;
        this.fish = false;
        this.develop = false;
      } else if (key == 4) {
        this.common = false;
        this.minigame = false;
        this.trial = false;
        this.fish = true;
        this.develop = false;
      } else if (key == 5) {
        this.common = false;
        this.minigame = false;
        this.trial = false;
        this.fish = false;
        this.develop = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-menu-demo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  .item {
    width: 15rem;
    font-size: 18px;
  }
  .el-menu-item.is-active {
    background-color: rgb(0, 183, 255);
    color: white;
  }
}
// .html {
//   background: url("../../static/bg/mainBg.jpg");
// }
</style>
