export function isNewDay(date) {
  const oldDate = new Date(date);
  const curDate = new Date();
  const oldYear = oldDate.getFullYear();
  const oldMonth = oldDate.getMonth();
  const oldDay = oldDate.getDate();
  const curYear = curDate.getFullYear();
  const curMonth = curDate.getMonth();
  const curDay = curDate.getDate();
  if (curYear > oldYear) {
    return true;
  }
  if (curMonth > oldMonth) {
    return true;
  }
  if (curDay > oldDay) {
    return true;
  }
  return false;
}
