import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import common from "../components/common.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/common",
    name: "common",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: common,
    redirect: "/common",
    children: [
      {
        path: "/common",
        component: common,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
